import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, EMPTY, merge, Observable, of } from 'rxjs';
import {
  catchError,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AppId, DimensionTitle, IDimension, IDimensionApp } from '@ultra/core/models';
import { IUserRoles, PermissionService, UserResourceAccess } from '@ultra/core/permissions';
import { MixpanelEvent, MixpanelService, RepositoryStates, UltraosApiService } from '@ultra/core/services';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';
import { AppDeepLinkTarget, DeepLinkService } from '@ultra/shared/services/deeplink';

import {
  filterDimensionsByUserRolesAndResourceAccess,
  findAppInDimension,
  isGameStoreApp,
  isMarketplaceApp,
  isWalletApp,
  trackedApps,
} from './universe-nav.helper';

export interface NavState {
  dimensions: IDimension[];
  apps: IDimensionApp[];
  activeDimension: IDimension;
  activeApp: IDimensionApp;
  activeGroupId: string;
}

export interface IDimensionState {
  dimensionName: string;
  appName: string;
  url?: string;
}

type GameInstaller = {
  gameId: string;
  tokenFactoryId: string;
  repositoryId: string;
};

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UniverseNavService {
  private navState: NavState = {
    dimensions: [],
    apps: [],
    activeDimension: null,
    activeApp: null,
    activeGroupId: null,
  };
  private store = new BehaviorSubject<NavState>(this.navState);
  private state$ = this.store
    .asObservable()
    .pipe(
      filter(
        (navState: NavState) =>
          !!navState.dimensions.length && !!navState.apps.length && !!navState.activeDimension && !!navState.activeApp,
      ),
    );

  dimensions$ = this.state$.pipe(
    map((state) => state.dimensions),
    distinctUntilChanged(),
  );

  apps$ = this.state$.pipe(
    map((state) => state.apps),
    distinctUntilChanged(),
  );

  activeDimension$ = this.state$.pipe(
    map((state) => state.activeDimension),
    distinctUntilChanged(),
  );

  activeApp$ = this.state$.pipe(
    map((state) => state.activeApp),
    distinctUntilChanged(),
  );

  private allDimensions$: Observable<IDimension[]> = this.ultraosApiService.getDimensions().pipe(shareReplay(1));

  private userRoles$: Observable<IUserRoles> = this.permissionService.userRoles$.pipe(
    untilDestroyed(this),
    shareReplay(1),
  );

  private userResourceAccess$: Observable<UserResourceAccess> = this.permissionService.userResourceAccess$.pipe(
    untilDestroyed(this),
    shareReplay(1),
  );

  private applicationDeepLink$: Observable<AppDeepLinkTarget> = this.deepLinkService.applicationDeepLink$.pipe(
    untilDestroyed(this),
  );

  private filteredDimensions$ = this.userRoles$.pipe(
    combineLatestWith(this.allDimensions$, this.userResourceAccess$),
    map(([roles, dimensions, resourceAccess]) =>
      filterDimensionsByUserRolesAndResourceAccess(dimensions, roles, resourceAccess),
    ),
    tap((dimensions) => {
      const newState = { ...this.navState, dimensions };
      this.updateState(newState);
    }),
  );

  constructor(
    private ultraosApiService: UltraosApiService,
    private permissionService: PermissionService,
    private deepLinkService: DeepLinkService,
    private mixpanelService: MixpanelService,
    @Inject(APP_CONFIG) private readonly environment: IEnvironment,
  ) {
    this.filteredDimensions$
      .pipe(
        take(1),
        switchMap(() =>
          this.getLastDimension().pipe(
            withLatestFrom(this.userRoles$, this.getGameInstallerInfo()),
            switchMap(([value, roles, gameInstallerInfo]) => {
              if (gameInstallerInfo) {
                return this.isGameRepositoryInstalled(gameInstallerInfo.repositoryId).pipe(
                  map((gameInfoStored) => ({ value, roles, gameInstallerInfo, gameInfoStored })),
                );
              } else {
                return of({ value, roles, gameInstallerInfo, gameInfoStored: false });
              }
            }),
            tap(({ value, roles, gameInstallerInfo, gameInfoStored }) => {
              const canOpenWalletApp = ((roles.walletUser || roles.walletAdmin) && !roles.user) || !roles.gameStoreUser;
              if (gameInstallerInfo) {
                this.initGameAppWithInstaller(gameInstallerInfo, gameInfoStored);
                return;
              }
              if (canOpenWalletApp) {
                this.initWalletApp();
                return;
              }
              if (!value) {
                throw new Error('No Initial State');
              }
              const { dimensionName, appName } = value;
              const activeDimension: IDimension = this.navState.dimensions.find((item) => item.title === dimensionName);
              const activeApp: IDimensionApp = activeDimension.apps.find((item: IDimensionApp) => item.id === appName);
              if (!activeDimension || !activeApp) {
                throw new Error('No correct initial state');
              }
              const isMarketPlaceUser = roles.marketUser || roles.marketAdmin;
              if (isMarketplaceApp(activeApp.id) && !isMarketPlaceUser) {
                throw new Error('No permission for marketplace app');
              }
              this.initApp(activeApp, activeDimension);
            }),
            catchError(() => this.initDefaultApp()),
          ),
        ),
      )
      .subscribe();

    this.applicationDeepLink$.subscribe((deepLinkTarget: AppDeepLinkTarget) => {
      this.navigateAppDeeplink(deepLinkTarget.appId, deepLinkTarget.path);
    });
  }

  getLastDimension() {
    return this.ultraosApiService
      .getStorageData('activeDimension')
      .pipe(map((value) => this.extractDimensionAndAppNames(value)));
  }

  getGameInstallerInfo() {
    return this.ultraosApiService
      .getStorageData('gameInstaller')
      .pipe(map((value) => this.extractGameInstallerInfo(value)));
  }

  removeGameInstallerInfo() {
    return this.ultraosApiService.removeStorageData('gameInstaller');
  }

  private isGameRepositoryInstalled(repositoryId: string): Observable<boolean> {
    return this.ultraosApiService
      .isRepositoryInstalled(repositoryId, 'master', null)
      .pipe(map((result) => Object.values(result)[0] === RepositoryStates.INSTALLED));
  }

  saveDimensionState(dimension: IDimension, app: IDimensionApp) {
    const value = `${dimension.title}_${app.id}`;
    merge(
      this.ultraosApiService.setStorageData(`${dimension.title}`, value),
      this.ultraosApiService.setStorageData('activeDimension', value),
    ).subscribe();
  }

  getDimensionState(dimension: IDimension): Observable<IDimensionState> {
    return this.ultraosApiService
      .getStorageData(`${dimension.title}`)
      .pipe(map((value) => this.extractDimensionAndAppNames(value)));
  }

  getDimensions(): Observable<IDimension[]> {
    return this.ultraosApiService.getDimensions();
  }

  private navigateAppDeeplink(appId: AppId, path: string): void {
    if (this.navState.activeApp.id === appId) {
      if (this.ultraosApiService.isElectronClient) {
        this.ultraosApiService.openApp(this.navState.activeApp.url + path, true);
      } else {
        // chromium client
        this.ultraosApiService.openNewTabWrapper(this.navState.activeApp, path);
      }
    } else {
      let dimensionToActivate = this.navState.activeDimension;
      let appToActivate = findAppInDimension(this.navState.activeDimension, appId);
      if (!appToActivate) {
        dimensionToActivate = this.navState.dimensions.find((dimension) => {
          appToActivate = findAppInDimension(dimension, appId);
          return appToActivate;
        });
      }
      this.updateDimensionsState(dimensionToActivate, appToActivate);
      if (this.ultraosApiService.isElectronClient) {
        this.ultraosApiService.openApp(this.navState.activeApp.url + path, true);
      } else {
        // chromium client
        const newActiveGroupId = this.getGroupId(appToActivate.id, dimensionToActivate.title);
        this.ultraosApiService.openTabGroupWrapper(appToActivate, newActiveGroupId, path);
        // GP-17652 Need to wait Group to be open
        setTimeout(() => {
          this.ultraosApiService.openNewTabWrapper(appToActivate, path);
        }, 100);
      }
    }
  }

  private updateDimensionsState(dimension: IDimension, appToActivate: IDimensionApp): void {
    const newActiveGroupId = this.getGroupId(appToActivate.id, dimension.title);
    const dimensions = this.updateDimensionsActive(this.navState.dimensions, dimension);
    const apps = this.updateAppsActive(dimension.apps, appToActivate);
    const newNavState = {
      ...this.navState,
      apps,
      activeApp: appToActivate,
      activeGroupId: newActiveGroupId,
      dimensions,
      activeDimension: dimension,
    };
    this.updateState(newNavState);
    this.saveDimensionState(dimension, appToActivate);
  }

  launchDimension(dimension: IDimension): void {
    const activeDimension = this.navState.activeDimension;
    if (dimension.title === activeDimension.title) {
      return;
    }

    this.getDimensionState(dimension)
      .pipe(
        take(1),
        tap((value) => {
          let appToOpen: IDimensionApp = null;
          if (!value) {
            appToOpen = dimension.apps[0];
          } else {
            const { appName } = value;
            appToOpen = dimension.apps.find((item: IDimensionApp) => item.id === appName);
          }
          const dimensions = this.updateDimensionsActive(this.navState.dimensions, dimension);
          const newState = { ...this.navState, dimensions, activeDimension: dimension, apps: dimension.apps };
          this.updateState(newState);
          this.activateApp(appToOpen);
        }),
      )
      .subscribe();
  }

  launchApp(app: IDimensionApp): void {
    this.activateApp(app);
  }

  private extractDimensionAndAppNames(value) {
    if (!value) {
      return null;
    }
    const [dimensionName, appName] = value.split('_');
    return { dimensionName, appName };
  }

  private extractGameInstallerInfo(value): GameInstaller | undefined {
    return value ? (value as GameInstaller) : undefined;
  }

  private activateApp(app: IDimensionApp): void {
    const newActiveGroupId = this.getGroupId(app.id, this.navState.activeDimension.title);
    const currentActiveGroupId = this.navState.activeGroupId;
    if (currentActiveGroupId !== newActiveGroupId) {
      const newState = { ...this.navState, activeGroupId: newActiveGroupId };
      this.updateState(newState);
      this.ultraosApiService.openTabGroupWrapper(app, newActiveGroupId);
    } else {
      this.ultraosApiService.openNewTabWrapper(app);
    }

    const apps = this.updateAppsActive(this.navState.apps, app);
    const newNavState = { ...this.navState, apps, activeApp: app };
    this.updateState(newNavState);

    const activeDimension = this.navState.activeDimension;
    this.saveDimensionState(activeDimension, app);
    if (trackedApps.includes(app.id)) {
      this.mixpanelService.track(MixpanelEvent.APP_OPENED, { appName: app.id });
    }
  }

  private updateAppsActive(appsToUpdate: IDimensionApp[], activeApp: IDimensionApp): IDimensionApp[] {
    return appsToUpdate.map((app) => {
      const appToUpdate = { ...app };
      appToUpdate.active = appToUpdate.id === activeApp.id;
      return appToUpdate;
    });
  }

  private updateDimensionsActive(dimensions: IDimension[], activeDimension: IDimension): IDimension[] {
    return dimensions.map((dimension) => {
      const dimensionToUpdate = { ...dimension };
      dimensionToUpdate.active = dimension.title === activeDimension.title;
      return dimensionToUpdate;
    });
  }

  private updateState(state: NavState): void {
    this.store.next((this.navState = state));
  }

  private getGroupId(appId: AppId, dimensionTitle: DimensionTitle): string {
    return `${this.environment.name}_${dimensionTitle}_${appId}`;
  }

  private initWalletApp(): void {
    this.setActiveDimension(DimensionTitle.USER, (app) => isWalletApp(app.id));
  }

  private initGameAppWithInstaller(gameInstaller: GameInstaller, gameInfoStored: boolean): void {
    if (!gameInfoStored) {
      this.setActiveDimension(
        DimensionTitle.USER,
        (app) => isGameStoreApp(app.id),
        `get/${gameInstaller.gameId}/${gameInstaller.tokenFactoryId}`,
      );
    } else {
      this.setActiveDimension(
        DimensionTitle.USER,
        (app) => isGameStoreApp(app.id),
        `your-library?gameId=${gameInstaller.gameId}&gameFactoryId=${gameInstaller.tokenFactoryId}&action=launch`,
      );
      this.removeGameInstallerInfo().subscribe();
    }
    return;
  }

  private setActiveDimension(
    dimensionTitleFilter: string,
    appFilter: (app: IDimensionApp) => boolean,
    targetUrl?: string,
  ): void {
    const activeDimension = this.navState.dimensions.find((dimension) => dimension.title === dimensionTitleFilter);
    const activeApp = activeDimension.apps.find(appFilter);
    const activeGroupId = `${activeDimension.title}_${activeApp.id}`;
    const dimensions = this.updateDimensionsActive(this.navState.dimensions, activeDimension);
    const apps = this.updateAppsActive(activeDimension.apps, activeApp);
    const newState = { ...this.navState, dimensions, apps, activeDimension, activeApp, activeGroupId };
    this.updateState(newState);
    this.ultraosApiService.openTabGroupWrapper(activeApp, activeGroupId, targetUrl);
  }

  private initDefaultApp(): Observable<never> {
    const activeDimension = this.navState.dimensions.find((dimension) => dimension.title === DimensionTitle.USER);
    const dimensions = this.updateDimensionsActive(this.navState.dimensions, activeDimension);
    const newState = { ...this.navState, dimensions, apps: activeDimension.apps, activeDimension };
    this.updateState(newState);

    const appToActivate = activeDimension.apps.find((app) => isGameStoreApp(app.id));
    this.activateApp(appToActivate);
    return EMPTY;
  }

  private initApp(activeApp: IDimensionApp, activeDimension: IDimension): void {
    const activeGroupId = this.getGroupId(activeApp.id, activeDimension.title);
    const dimensions = this.updateDimensionsActive(this.navState.dimensions, activeDimension);
    const apps = this.updateAppsActive(activeDimension.apps, activeApp);
    const newState = { ...this.navState, dimensions, apps, activeDimension, activeApp, activeGroupId };
    this.updateState(newState);
    this.ultraosApiService.openTabGroupWrapper(activeApp, activeGroupId);
  }
}
